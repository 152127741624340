import React from 'react';
import {
  //FreeBreakfast as CoffeeIcon,
  CallSplit as CallSplitIcon
} from "@material-ui/icons";
// const handleDateFilter = (term, rowData) => {
//   return new Date(term).setHours(0, 0, 0, 0) <= new Date(rowData.due_date)
//     ? true
//     : false;
// };

export const deliveryOptions = {
  title: "",
  currentScreen: "test",
  dimensions: {
    datatable: {
      width: "98%",
      height: "70vh"
    },
    row: {
      height: "30px"
    }
  },
  keyColumn: "ID",
  data: {
    columns: [
      {
        id: "ID",
        label: "ID",
        colSize: "5px",
        editable: false,
        required: true,
        dataType: "text",
        // valueVerification: (val) => {
        //   const error = val === 298;
        //   const message = val === 298 ? "Value is not valid" : "";
        //   return {
        //     error,
        //     message
        //   };
        // }
      },
      {
        id: "BARCODE",//"consumer_barcode",
        label: "BARCODE",
        colSize: "45px",
        editable: true,
        dataType: "text"
      },
      {
        id: "ZONE",//"consumer_barcode",
        label: "ZONE",
        colSize: "45px",
        editable: true,
        dataType: "text"
      },
      {
        id: "INVOICE",//"consumer_barcode",
        label: "INVOICE",
        colSize: "45px",
        editable: true,
        dataType: "text"
      },
      {
        id: "STATUS",//"status",
        label: "STATUS",
        colSize: "30px",
        editable: true,
        inputType: "select",
        values: ["pending", "delivered", "rto"]
      },
      {
        id: "RELATION",//"relation",
        label: "RELATION",
        colSize: "45px",
        editable: true,
        dataType: "text"
      },
      {
        id: "NAME",//"name",
        label: "NAME",
        colSize: "120px",
        editable: true,
        dataType: "text"
      },
      {
        id: "PHONE",//"phone",
        label: "PHONE",
        colSize: "40px",
        editable: true,
        dataType: "text"
      },
      {
        id: "ADDRESS",//"address",
        label: "ADDRESS",
        colSize: "200px",
        editable: true,
        dataType: "text"
      },
      {
        id: "LOCATION",//"location",
        label: "LOCATION",
        colSize: "120px",
        editable: true,
        dataType: "string",
        valueVerification: (val) => {
          // if (val.replace(/red/g, "orange")) {
          //   return {
          //     error: true,
          //     message: "Date can't be in the future"
          //   };
          // }
          // console.log(val);
          return {
            error: true,
            message: val.replace(/28.5959254/g, "-")
          };
        }
      },
      {
        id: "PHOTO",//"photo",
        label: "PHOTO",
        colSize: "50px",
        editable: true,
        dataType: "text"
      },
      {
        id: "SIGNATURE",//"photo",
        label: "SIGNATURE",
        colSize: "50px",
        editable: true,
        dataType: "text"
      },
      {
        id: "COMMENTS",//"comments",
        label: "COMMENTS",
        colSize: "120px",
        editable: true,
        dataType: "text"
      },
      {
        id: "DATETIME",//"dateTime_created",
        label: "DATETIME",
        colSize: "120px",
        editable: true,
        dataType: "time",
        dateFormatIn: "YYYY-MM-DD HH:mm:ss",
        dateFormatOut: "YYYY-MM-DD HH:mm:ss",
        inputType: "dateTime",
        disableFilters: true,
        // valueVerification: (val) => {
        //   if (new Date().getTime() < new Date(val).getTime()) {
        //     return {
        //       error: true,
        //       message: "Date can't be in the future"
        //     };
        //   }
        //   return {
        //     error: false,
        //     message: ""
        //   };
        // }
      },
      {
        id: "PACEID",//"user_id",
        label: "PACEID",
        colSize: "180px", //
        editable: true,
        dataType: "text"
      },
    ],
    rows: [],
  },
  features: {
    canEdit: false,
    canDelete: false,
    canPrint: true,
    canDownload: true,
    canSearch: true,
    canFilter: true,
    canRefreshRows: true,
    canOrderColumns: true,
    canSelectRow: true,
    canSaveUserConfiguration: false,
    // userConfiguration: {
    //   columnsOrder: ["ID", "BARCODE",],
    //   copyToClipboard: false
    // },
    rowsPerPage: {
      available: [10, 25, 50, 100],
      selected: 10
    },
    // additionalIcons: [
    //   {
    //     title: "Coffee",
    //     icon: <CoffeeIcon color="primary" />,
    //     onClick: () => alert("Coffee Time!")
    //   }
    // ],
    selectionIcons: [
      {
        title: "Selected Rows",
        icon: <CallSplitIcon color="primary" />,
        onClick: rows => console.log(rows)
      }
    ]
  }
};
