/* eslint import/no-anonymous-default-export: [2, {"allowAnonymousFunction": true}] */
import {
  FETCH_DELIVERIES,
  UPDATE_DELIVERIES,
  FETCH_DELIVERIIES_LOADING,
  FETCH_DELIVERIES_ERROR,
  UPDATE_DELIVERY_STATUS,
  FETCH_DASHBOARDS_LOADING,
  FETCH_DASHBOARDS_ERROR,
  FETCH_DASHBOARDS,
  UPDATE_SEARCH_OPTION,
} from "../constants/types";
import { deliveryOptions } from "../constants/TableOptions";

const initialState = {
  deliveries: deliveryOptions,
  deliveriesLoading: true,
  deliveriesError: false,
  deliveryStatus: false,
  searchOption: 0,
  totalDeliveriesObj: {
    totalDeliveriesCnt: 0,
    curentMonthDeliveriesCnt: 0,
    lastMonthDeliveriesCnt: 0,
  },

};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_DELIVERIES:
      return {
        ...state,
        deliveries: action.payload,
        deliveriesLoading: false,
        deliveriesError: false,
        deliveryStatus: true,
      };
    case UPDATE_DELIVERIES:
      return {
        ...state,
        deliveries: action.payload,
        deliveriesLoading: false,
        deliveriesError: false,
        deliveryStatus: true,
      };
    case FETCH_DELIVERIIES_LOADING:
      return {
        ...state,
        deliveriesLoading: action.payload,
      };
    case FETCH_DELIVERIES_ERROR:
      return {
        ...state,
        deliveriesError: action.payload,
      };
    case UPDATE_DELIVERY_STATUS:
      return {
        ...state,
        deliveryStatus: false,
      };
    case UPDATE_SEARCH_OPTION:
      return {
        ...state,
        searchOption: action.payload,
      };
    case FETCH_DASHBOARDS_LOADING:
      return {
        ...state,
        totalDeliveriesObj: action.payload,
      };
    case FETCH_DASHBOARDS_ERROR:
      return {
        ...state,
        totalDeliveriesObj: action.payload,
      };
    case FETCH_DASHBOARDS:
      return {
        ...state,
        totalDeliveriesObj: action.payload,
      };

    default:
      return state;
  }
}
