import moment from "moment";

//common use methods
export const getCurrentDate = () => {
  return new Date();
};

export const dateFormatter = (date) => {
  if (!date) return;
  return moment(date).format("MMM D YYYY, H:mm:ss");
};

export const dateWithFormatter = (date, format) => {
  if (!date) return "--";
  return moment(date).format(format);
};
export const getFormattedCurrentDate = (format = "DD-MMM-YYYY") => {
  return moment().format(format);
};

export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

export const totalDaysInMonth = () => {
  const today = new Date();
  const month = today.getMonth();
  return daysInMonth(month + 1, today.getFullYear());
};

export const validateEmail = (email) => {
  // Need a solution of the below regex escape eslint issue.
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getData = (response) => {
  if (response.data && response.data.payload) {
    return response.data.payload;
  }
  if (response.data) {
    return response.data;
  }
  return [];
};

export const mapPayload = (payload, mapper) => {
  let response = {};
  for (let field in mapper) {
    if (payload[field] === undefined || payload[field] === null) {
      response[field] = mapper[field];
    } else if (
      typeof payload[field] === "object" &&
      !Array.isArray(payload[field])
    ) {
      response[field] = mapPayload(payload[field], mapper[field]);
    } else {
      response[field] = payload[field];
    }
  }

  return response;
};

// Check if provided string contains JSON
export const isJSON = (str) => {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};

// check if provided value is numeric value
export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};
