//http-services

import { HTTPClient } from "./api-service";
import Config from '../constants/Config';
//import Cookies from "js-cookie";
import { deliveryOptions } from "../constants/TableOptions";
import { AnchorElement, AnchorImgElement } from "../components/Utility";

/*
export async function getAllConsumers(email = 'Test@mail.com', userType = 'executive') {
  const formBody = {
    email: email,
    userType: userType,
  };
  return await HTTPClient({
    url: Config.getAllConsumers,
    method: "POST",
    data: formBody,
    skipAuth: true,
  }).then((response) => {
    //Todo:need to check request payload
    //Todo:need to set access token
    //Todo:need to set refresh token

    return response;
  });
}*/

export async function getAllWebConsumers(email = null, dateRange = null, bybarcode = null) {
  const formBody = {
    email: email,
    dateRange: dateRange,
    bybarcode: bybarcode,
  };
  //setLoading(true);
  let getTableData = [];
  let updatedOptionsData = deliveryOptions;
  //console.log(4);
  return await HTTPClient({
    url: Config.getAllWebConsumers,
    method: "POST",
    data: formBody,
    skipAuth: true,
  }).then((response) => {
    // console.log(6);
    //Hide Loader
    let result = response.data;
    // setLoading(false);
    // If server response message same as Data Matched
    if (result?.success === 1) {
      // console.log(7);
      // if (result.data.length) setTotalCount(result.data.length);
      let i = 1;
      result.data.forEach((element, index) => {
        let columnValue;
        Object.entries(element).forEach((val, key) => {
          if (val[0] === 'ID') {
            columnValue = i; i++; //
            element['ID'] = columnValue;
          } else if (val[0] === 'LOCATION' && element['LOCATION'] !== null && element['LOCATON'] !== '' && typeof element['LOCATION'] == "string") {
            element['LOCATION'] = AnchorElement(val[1].replace(/,/g, "-"));//val[1].replace(/,/g, "-");
            //val.replace(/28.5959254/g, "-")
          } else if (val[0] === 'PHOTO' && element['PHOTO'] !== null && element['PHOTO'] !== '' && typeof element['PHOTO'] == "string") {
            // console.log(element['PHOTO'], val[1]);
            element['PHOTO'] = AnchorImgElement(val[1]);//val[1].replace(/,/g, "-");
            //val.replace(/28.5959254/g, "-")
          } else if (val[0] === 'SIGNATURE' && element['SIGNATURE'] !== null && element['SIGNATURE'] !== '' && typeof element['SIGNATURE'] == "string") {
            // console.log(element['SIGNATURE'], val[1]);
            element['SIGNATURE'] = AnchorImgElement(val[1]);
          }
          else {
            columnValue = val[1] === '' ? '-' : val[1];
            element[val[0]] = columnValue;
          }
        });

        getTableData.push(element);
      });
      //  setTableData(getTableData);
      //  setCsvData(result.data);
      //updatedOptionsData = deliveryOptions;
      // console.log(9);
      updatedOptionsData.data.rows = getTableData;
      //  setOptionsData(updatedOptionsData);
    }
    // console.log(10);
    return updatedOptionsData;
  });
}

export async function getAllDashboards(email = null) {
  const formBody = {
    email: email,
  };

  // if (captchaValue) formBody.captchaToken = captchaValue;

  return await HTTPClient({
    url: Config.getAllDashboards,
    method: "POST",
    data: formBody,
    skipAuth: true,
  }).then((response) => {
    //Todo:need to check request payload
    //Todo:need to set access token
    //Todo:need to set refresh token
    return response;
  });
}

/*
export async function deliveryFormSubmission(employeeId, dateTime, location = 'No Location', address, barCode, name, phone, status, relation, photo = 'No Photo', comment) {
  const formBody = {
    employeeId: employeeId,
    dateTime: dateTime,
    location: location,
    address: address,
    barCode: barCode,
    name: name,
    phone: phone,
    status: status,
    relation: relation,
    photo: photo,
    comment: comment,
  };

  // if (captchaValue) formBody.captchaToken = captchaValue;

  return await HTTPClient({
    url: Config.deliverySubmission,
    method: "POST",
    data: formBody,
    skipAuth: true,
  }).then((response) => {
    //Todo:need to check request payload
    //Todo:need to set access token
    //Todo:need to set refresh token
    return response;
  });
}
*/
