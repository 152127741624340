import axios from "axios";
import queryString from "query-string";
//import { logout } from "./user-service";

export function HTTPClient({
  url,
  data,
  method,
  headers,
  query,
  skipAuth,
  responseType,
}) {
  if (!data) data = {};
  if (!method) method = "GET";
  if (!query) query = null;
  if (!skipAuth) skipAuth = true;
  if (!responseType) responseType = "json";

  if (!headers)
    headers = {
      "Content-Type": "application/json; charset=UTF-8",
      // "Accept-Language": "en",
      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Methods": "*",
      // "Access-Control-Allow-Headers": '"Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept, x-client-key, x-client-token, x-client-secret, Authorization"',
    };

  //Todo: Need to validate header Authorization

  if (query) {
    url = url + "?" + queryString.stringify(query, { strict: false });
  }

  let axiosOption = {
    url,
    method,
    headers,
    responseType,
  };

  //Todo: Needed to do cancellation of request in case we need to cancel the request

  if (method !== "GET") {
    axiosOption.data = data;
  }

  const axiosInstance = axios.create({});

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error && error.response && error.response.status === 401) {
        console.log('logout(); Called');
      }
      if (error && error?.message === "Cancelled") {
        return {
          isError: true,
          cancelled: true,
          error,
        };
      }

      if (error.response) {
        // Request made and server responded
        console.log(error.response.status);
        console.log(error.response.data);
        console.log(error.response.headers);
        // The request was made but no response was received
        console.log(error.request);
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      //this.handleAxiosError(error);
      return {
        isError: true,
        error,
      };
    }
  );

  return axiosInstance(axiosOption);
}
