import React, { useState, useEffect, } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, } from '@fortawesome/free-solid-svg-icons'; //faCheck, faCog, faSearch
import { Breadcrumb, Form } from '@themesberg/react-bootstrap'; //Col, Row, Form, Button, ButtonGroup, InputGroup, Dropdown
import {
  //setAccessToken as saveSessionInCookie,
  isLoggedIn,
} from "../services/user-service";
import { Redirect } from "react-router-dom";
import { Routes } from "../routes";
//import { DeliveryTransactionsTable } from "../components/Tables";
//import { PageTrafficTable, RankingTable } from "../components/Tables";

import { Datatable } from "@o2xp/react-datatable";
import { chunk } from "lodash";
import { deliveryOptions } from "../constants/TableOptions";
import { getAllDeliveries, updateDeliveryStatus, updateSearchOption } from "../actions/delivery-actions";
import DatePicker from 'react-datepicker';
import "../assets/scss/react-datepicker.css";
import moment from "moment-timezone";
export default function DeliveryTransactions() {

  //let localStorePackageVersion = localStorage.getItem('build-version');
  //const [loading, setLoading] = useState(false);
  //const [totalCount, setTotalCount] = useState(0);
  //const [userType] = useState(localStorage.getItem('userType')); //localStorage.getItem('build-version');
  const [userEmail] = useState(localStorage.getItem('email')); //localStorage.getItem('build-version');//
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [bpnumber, setBpnumber] = useState(null);
  //const [option, setOption] = useState([0, null]);
  const getSearchOption = useSelector((store) => store.deliveryStates.searchOption);
  const getData = useSelector((store) => store.deliveryStates.deliveries);
  const getRowsData = useSelector((store) => store?.deliveryStates?.deliveries?.data?.rows);
  const deliveries = useSelector((store) => {
    return store?.deliveryStates?.deliveries;
  });


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateDeliveryStatus());
  }, [getRowsData]);

  useEffect(() => {
    //console.log(bpnumber);
    if (bpnumber !== null && userEmail !== null && (bpnumber.length > 9)) dispatch(getAllDeliveries(userEmail, null, bpnumber));
  }, [bpnumber]); //dispatch

  useEffect(() => {
    if (dateRange[0] && dateRange[1] && userEmail !== null) dispatch(getAllDeliveries(userEmail, (moment(dateRange[0]).format("YYYY-MM-DD") + "," + moment(dateRange[1]).format("YYYY-MM-DD"))));
    else if (dateRange[0] === null && dateRange[1] === null && userEmail !== null && bpnumber === null) dispatch(getAllDeliveries(userEmail, null));
  }, [dateRange]); //dispatch

  const updateBpnumber = (e) => {
    setBpnumber(e.target.value);
  };

  const resetBpnumber = () => {
    setBpnumber("");
    setDateRange([null, null]);
  };

  // const actionsRow = ({ type, payload }) => {
  //   console.log(type);
  //   console.log(payload);
  // };

  const refreshRows = () => {
    const { rows } = getData?.data;
    const randomRows = Math.floor(Math.random() * rows.length) + 1;
    const randomTime = Math.floor(Math.random() * 4000) + 1000;
    const randomResolve = Math.floor(Math.random() * 10) + 1;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (randomResolve > 3) {
          resolve(chunk(rows, randomRows)[0]);
        }
        reject(new Error("err"));
      }, randomTime);
    });
  };

  if (!isLoggedIn()) return <Redirect to={Routes.Signin.path} />;

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Deliveries</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Deliveries</h4>
          <p className="mb-0">Filter deliveries by Date and BP number.</p>

          <fieldset>

            <Form.Check
              defaultChecked={getSearchOption === 0}
              type="radio"
              defaultValue="date"
              label="Search By Date"
              name="SearchByOption"
              id="bydate"
              htmlFor="bydate"
              onClick={(e) => dispatch(updateSearchOption(0))}
            />
            <Form.Check
              defaultChecked={getSearchOption === 1}
              type="radio"
              defaultValue="bpnumber"
              label="Search By BP number"
              name="SearchByOption"
              id="bybpnumber"
              htmlFor="bybpnumber"
              onClick={(e) => dispatch(updateSearchOption(1))}
            />
          </fieldset>

          {getSearchOption === 1 &&
            <>
              <Form onSubmit={(e) => e.preventDefault(e)}>
                <Form.Group className="mb-3">
                  {/* <Form.Label>Email address</Form.Label> onChange={(e) => updateBpnumber(e)} */}
                  <Form.Control name="bpnumber" type="text" onChange={(e) => updateBpnumber(e)} value={bpnumber} placeholder="Please type your BP number" />
                  {bpnumber && <button type="button" onClick={(e) => resetBpnumber()} style={{ top: '-35px', left: '300px' }} class="react-datepicker__close-icon" aria-label="Close" tabindex="-1"></button>}
                </Form.Group>
                {/* <Form.Group className="mb-3">
                  <Button variant="primary" onClick={(e) => updateBpnumber(e)} type="submit" className="w-30">
                    Search
                  </Button>
                </Form.Group> */}
              </Form>
            </>
          }
          {getSearchOption === 0 && <DatePicker
            dateFormat="MMMM d, yyyy"
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              console.log('ok');
              setDateRange(update);
            }}
            isClearable={true}
            placeholderText="Click to select a date range"
          //selected="August 31, 2022 - August 31, 2022"
          />}
          <p className="mb-0">Total Results:-{deliveries ? deliveries?.data?.rows?.length : deliveryOptions?.data?.rows?.length}</p>

        </div>
        {/* <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
          </ButtonGroup>
        </div> */}
      </div>

      {/* <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">

        </Row>
      </div> */}

      {/* {console.log('deliveries', deliveries)} */}
      <Datatable
        options={deliveries ? deliveries : deliveryOptions}
        stripped
        refreshRows={refreshRows}
        // actions={actionsRow}
        forceRerender
      />

    </>
  );
};