import { Datatable } from "@o2xp/react-datatable";
import React from 'react';
import { getWebUsers, updateWebUser } from '../services/user-service';
import { useState, useEffect } from 'react';
import {
  // FreeBreakfast as CoffeeIcon,
  CallSplit as CallSplitIcon
} from "@material-ui/icons";
//import { chunk } from "lodash";


const options = {
  title: "",
  currentScreen: "users",
  dimensions: {
    datatable: {
      width: "98%",
      height: "70vh"
    }
  },
  keyColumn: "id",
  font: "Arial",
  data: {
    columns: [
      {
        id: "id",
        label: "ID",
        colSize: "15px",
        editable: false,
        required: true,
        dataType: "text",
      },
      {
        id: "name",
        label: "NAME",
        colSize: "120px",
        editable: true,
        dataType: "text"
      },
      {
        id: "email",
        label: "EMAIL",
        colSize: "120px",
        editable: false,
        dataType: "text"
      },
      {
        id: "vendor",
        label: "TEAM",
        colSize: "120px",
        editable: true,
        dataType: "text"
      },
      {
        id: "userType",
        label: "USER TYPE",
        colSize: "90px",
        editable: true,
        dataType: "text"
      },
      {
        id: "app_version",
        label: "APP VERSION",
        colSize: "100px",
        editable: false,
        dataType: "string",
      },
      {
        id: "accountStatus",
        label: "ACCOUNT STATUS",
        colSize: "100px",
        editable: true,
        dataType: "string",
      },
      {
        id: "unSyncCnt",
        label: "UNSYNC COUNT",
        colSize: "100px",
        editable: false,
        dataType: "number",
      },
      {
        id: "date_modified",
        label: "LAST ACTIVE",
        colSize: "180px",
        editable: false,
        // dataType: "text",
        dataType: "time",
        dateFormatIn: "YYYY-MM-DD HH:mm:ss",
        dateFormatOut: "YYYY-MM-DD HH:mm:ss",
        inputType: "dateTime",
        valueVerification: (val) => {
          if (new Date().getTime() < new Date(val).getTime()) {
            return {
              error: true,
              message: "Date can't be in the future"
            };
          }
          return {
            error: false,
            message: ""
          };
        }
      },
      {
        id: "portalAuthorization",
        label: "PORTAL ACCESS",
        colSize: "100px",
        editable: true,
        dataType: "string",
      },
      {
        id: "age",
        label: "AGE",
        colSize: "35px",
        editable: true,
        dataType: "number",
        valueVerification: (val) => {
          let error;
          let message;
          switch (true) {
            case val > 100:
              error = true;
              message = "Value is too big";
              break;
            case val < 1:
              error = true;
              message = "Value is too low";
              break;
            default:
              error = false;
              message = "";
              break;
          }

          return {
            error,
            message
          };
        }
      },
      {
        id: "login_status",
        label: "LOGIN STATUS",
        colSize: "100px",
        editable: false,
        dataType: "boolean",
      },
      {
        id: "phone",
        label: "PHONE",
        colSize: "45px",
        editable: true,
        dataType: "text"
      },
      {
        id: "address",
        label: "ADDRESS",
        colSize: "250px",
        editable: true,
        dataType: "text"
      },
      {
        id: "date_created",
        label: "DATE CREATED",
        colSize: "180px",
        editable: false,
        dataType: "time",
        dateFormatIn: "YYYY-MM-DD HH:mm:ss",
        dateFormatOut: "YYYY-MM-DD HH:mm:ss",
        inputType: "dateTime",
        valueVerification: (val) => {
          if (new Date().getTime() < new Date(val).getTime()) {
            return {
              error: true,
              message: "Date can't be in the future"
            };
          }
          return {
            error: false,
            message: ""
          };
        }
      },
    ],
    rows: [],
  },
  features: {
    canEdit: false,
    canDelete: false,
    canPrint: true,
    canDownload: true,
    canSearch: true,
    canFilter: true,
    canRefreshRows: false,
    canOrderColumns: true,
    canSelectRow: false,
    canSaveUserConfiguration: false,
    // userConfiguration: {
    //   columnsOrder: ["id", "name", "email", "phone", "address", "userType", "age", "login_status", "date_modified", "date_created"],
    //   copyToClipboard: false
    // },
    rowsPerPage: {
      available: [25, 50, 100],
      selected: 25
    },
    // additionalIcons: [
    //   {
    //     title: "Coffee",
    //     icon: <CoffeeIcon color="primary" />,
    //     onClick: () => alert("Coffee Time!")
    //   }
    // ],
    selectionIcons: [
      {
        title: "Selected Rows",
        icon: <CallSplitIcon color="primary" />,
        onClick: rows => console.log(rows)
      }
    ]
  }
};

const PaceUsersTable = (props) => {
  // const [isRegistraionSuccess, setIsRegistraionSuccess] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const [errortext, setErrortext] = useState('');
  // const [tableData, setTableData] = useState([]);
  //const [csvData, setCsvData] = useState([]);
  // const [totalCount, setTotalCount] = useState(0);
  const [optionsData, setOptionsData] = useState(options);

  const userType = localStorage.getItem('userType');
  const userEmail = localStorage.getItem('email');

  useEffect(() => {
    loadTableData();
  });

  const loadTableData = () => {
    //setLoading(true);
    let getTableData = [];
    getWebUsers(userEmail)
      .then((response) => {
        //Hide Loader
        //console.log(response);
        let result = response.data;
        //setLoading(false);
        // If server response message same as Data Matched
        if (result.success === 1) {
          // if (result.data.length) setTotalCount(result.data.length);
          let i = 1;
          result.data.forEach((element, index) => {
            let columnValue;
            //console.log(element, index);
            Object.entries(element).forEach((val, key) => {
              if (val[0] === 'id') {
                columnValue = i; i++; //
                element['id'] = columnValue;
              } if (val[0] === 'login_status') {
                columnValue = i; i++; //
                element['login_status'] = val[1] === 0 ? 'Inactive' : 'Active';
              } if (val[0] === 'portalAuthorization') {
                columnValue = i; i++; //
                element['portalAuthorization'] = val[1] === '0' ? 'False' : 'True';
              } else {
                columnValue = val[1] === '' ? '-' : val[1];
                element[val[0]] = columnValue;
              }
            });
            getTableData.push(element);
          });
          //setTableData(getTableData);
          //setCsvData(result.data);
          if (userType === 'admin') {
            let finalOptionsData = optionsData.data.columns.filter((e) => e.id !== 'phone');
            optionsData.data.columns = finalOptionsData;
          }

          let updatedOptionsData = optionsData;
          updatedOptionsData.features.canEdit = (userType === 'superadmin' ? true : false);
          updatedOptionsData.data.rows = getTableData;
          setOptionsData(updatedOptionsData);
          props.onLoadData(getTableData.length);
        } else {
          // setTimeout(() => {
          //   // setErrortext(result.message);
          // }, 1000);
        }
      })
      .catch((error) => {
        //Hide Loader
        //setLoading(false);
        console.error(error);
      });
  };

  /* const dateFormat = () => {
     let today = new Date();
     let monthCalc = today.getMonth() + 1;
     let month = monthCalc < 10 ? '0' + monthCalc : monthCalc;
 
     let days = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
     let todayDateInString = days + '-' + month + '-' + today.getFullYear();
     return todayDateInString;
   };
 
   const currentTime = () => {
     return new Date().toLocaleTimeString();
   };
 */
  const actionsRow = ({ type, payload }) => {
    if (type === 'save') {
      //setLoading(true);
      updateWebUser(payload)
        .then((response) => {
          //Hide Loader
          //console.log(response);
          let result = response.data;
          //setLoading(false);
          // If server response message same as Data Matched
          if (result.success === 1) {
            alert(result.message);
            // if (result.data.length) setTotalCount(result.data.length);
          } else {
            alert(result.message);
            loadTableData();
          }
        })
        .catch((error) => {
          //Hide Loader
          //setLoading(false);
          console.error(error);
        });
    }
    // console.log(type);
    // console.log(payload);
    return false;
  };

  const refreshRows = () => {
    if (optionsData?.data?.rows != null) loadTableData();
    // const { rows } = options.data;
    // const randomRows = Math.floor(Math.random() * rows.length) + 1;
    // const randomTime = Math.floor(Math.random() * 4000) + 1000;
    // const randomResolve = Math.floor(Math.random() * 10) + 1;
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     if (randomResolve > 3) {
    //       resolve(chunk(rows, randomRows)[0]);
    //     }
    //     reject(new Error("err"));
    //   }, randomTime);
    // });
  };

  return <Datatable options={optionsData}
    stripped
    refreshRows={refreshRows}
    actions={actionsRow}
    forceRerender
  />;
};

export default PaceUsersTable;

