const Config = {
  domainHost: 'https://pacedelivery.in/',
  login: 'https://pacedelivery.in/api/web/login.php',
  //deliverySubmission: 'https://pacedelivery.in/api/deliverySubmission.php',
  //photoSubmission: 'https://pacedelivery.in/api/photoSubmission.php',
  //getConsumerDetails: 'https://pacedelivery.in/api/getConsumerDetails.php',
  //userRegistrations: 'https://pacedelivery.in/api/register.php',
  //userManagement: 'https://mocki.io/v1/afc6b219-70ad-4acb-b7e1-8d12fa1134f5', // mockdata
  getWebUsers: 'https://pacedelivery.in/api/web/getWebUsers.php',
  updateWebUser: 'https://pacedelivery.in/api/web/updateWebUser.php',
  //getAllConsumers: 'https://pacedelivery.in/api/getConsumers.php',
  getAllWebConsumers: 'https://pacedelivery.in/api/web/getWebConsumers.php',
  getAllDashboards: 'https://pacedelivery.in/api/web/webDashboards.php',
  accessTokenKey: 'access_token',
  devMode: true,
};

export default Config;
