import React from 'react';
export function AnchorElement(propLocation) {
  let url = 'http://www.google.com/maps/place/' + propLocation.toString().replace('-', ',');
  return (
    <>
      <a href={url} target="_new">View</a>
    </>
  );
}

export function AnchorImgElement(propURL) {
  return (
    <>
      <a href={propURL} target="_new">
        <img alt="paceDelivery.in" src={propURL} width="50" height="50" />
      </a>
    </>
  );
}
