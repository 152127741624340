import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
//import { composeWithDevTools } from 'redux-devtools-extension';
import PackageJSON from '../package.json';
// import monitorReducersEnhancer from './enhancer/monitorReducer';
// import loggerMiddleware from './middleware/logger';
import rootReducer from './reducers';


function checkBuildVersion() {
  let currentPackageVersion = PackageJSON.version;
  let localStorePackageVersion = localStorage.getItem('build-version');
  if (currentPackageVersion !== localStorePackageVersion) {
    localStorage.removeItem('build-version');
    localStorage.removeItem('state');
    localStorage.setItem('state', JSON.stringify({}));
    localStorage.setItem('build-version', currentPackageVersion);
    window.location.reload();
  }
}

checkBuildVersion();

export default function configureStore(preloadedState) {
  const middlewares = [thunkMiddleware];//loggerMiddleware,
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];//, monitorReducersEnhancer
  const composedEnhancers = compose(...enhancers);
  // const composedEnhancers = composeWithDevTools( ...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);
  if (process.env.REACT_APP_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));
  }
  return store;
}