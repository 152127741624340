
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faChartLine } from '@fortawesome/free-solid-svg-icons'; //, faCloudUploadAlt, faPlus, faRocket, faTasks, faUserShield
import { Col, Row } from '@themesberg/react-bootstrap'; //, Button, Dropdown, ButtonGroup
import {
  // setAccessToken as saveSessionInCookie,
  isLoggedIn,
} from "../../services/user-service";
import moment from "moment";
import { getAllDashboards } from "../../actions/delivery-actions";
import { getFormattedCurrentDate } from "../../services/common-utilities";
// import { Link } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import { Routes } from "../../routes";
import { CounterWidget, CircleChartWidget,/* BarChartWidget ,*/ TeamMembersWidget, SalesValueWidget, SalesValueWidgetPhone, ProgressTrackWidget } from "../../components/Widgets"; // , RankingWidget, AcquisitionWidget
// import { PageVisitsTable } from "../../components/Tables";
import { trafficShares/*, totalOrders*/ } from "../../data/charts";

export default () => {
  if (!isLoggedIn()) return <Redirect to={Routes.Signin.path} />;
  const dispatch = useDispatch();
  const lastMonthDate = 'Total deliveries in ' + moment().subtract(1, 'months').format('MMMM');
  const forCurrentMonth = 'Total Deliveries in ' + getFormattedCurrentDate('MMMM');
  const { totalDeliveriesObj } = useSelector((store) => store.deliveryStates);
  const userEmail = localStorage.getItem('email');
  const userType = localStorage.getItem('userType');

  useEffect(() => {
    if (userEmail !== null || userEmail !== undefined) {
      dispatch(getAllDashboards(userEmail));
    }
    // setTimeout(() => setTotalDeliveries(totalDeliveriesObj), 2000);
  }, [userEmail]);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        {/* <Dropdown className="btn-toolbar">
          <Dropdown.Toggle as={Button} variant="primary" size="sm" className="me-2">
            <FontAwesomeIcon icon={faPlus} className="me-2" />New Task1
          </Dropdown.Toggle>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faTasks} className="me-2" /> New Task
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" /> Upload Files
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faUserShield} className="me-2" /> Preview Security
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item className="fw-bold">
              <FontAwesomeIcon icon={faRocket} className="text-danger me-2" /> Upgrade to Pro
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> 

        <ButtonGroup>
          <Button variant="outline-primary" size="sm">Share</Button>
          <Button variant="outline-primary" size="sm">Export</Button>
        </ButtonGroup>*/}
      </div>

      <Row className="justify-content-md-center">
        <Col xs={12} className="mb-4 d-none d-sm-block">
          <SalesValueWidget
            title="Total Deliveries"
            value={totalDeliveriesObj.totalDeliveriesCnt}
            percentage={10.57}
          />
        </Col>
        <Col xs={12} className="mb-4 d-sm-none">
          <SalesValueWidgetPhone
            title="Total Deliveries"
            value={totalDeliveriesObj.totalDeliveriesCnt}
            percentage={10.57}
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category={forCurrentMonth}
            title={totalDeliveriesObj.curentMonthDeliveriesCnt}
            period={moment().startOf('month').format('D-MMMM') + ' - ' + getFormattedCurrentDate('D-MMMM')}
            percentage={28.4}
            icon={faCashRegister}
            iconColor="shape-tertiary"
          />
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category={lastMonthDate}
            title={totalDeliveriesObj.lastMonthDeliveriesCnt}
            period={moment().subtract(1, 'months').startOf('month').format('D-MMMM') + ' - ' + moment().subtract(1, 'months').endOf('month').format('D-MMMM')}
            percentage={18.2}
            icon={faChartLine}
            iconColor="shape-secondary"
          />
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CircleChartWidget
            title="Delivery executed by"
            data={trafficShares} />
        </Col>
      </Row>

      <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Row>
            <Col xs={12} xl={8} className="mb-4">
              <Row>
                <Col xs={12} className="mb-4">
                  {/* <PageVisitsTable /> */}
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  {userType !== 'vendor' && <TeamMembersWidget />}
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  {userType !== 'vendor' && <ProgressTrackWidget />}
                </Col>
              </Row>
            </Col>

            <Col xs={12} xl={4}>
              <Row>
                {/*} <Col xs={12} className="mb-4">
                  <BarChartWidget
                    title="Last month delivery"
                    value={3711}
                    percentage={18.2}
                    data={totalOrders} />
                </Col>

                <Col xs={12} className="px-0 mb-4">
                  {<RankingWidget />}
                </Col>

                <Col xs={12} className="px-0">
                  {<AcquisitionWidget />}
      </Col>{*/}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
